<style scoped>

</style>

<script>
import {HalfCircleSpinner} from "epic-spinners";
import TimeAgo from "vue2-timeago";
import CopyableInput from "@/components/CopyableInput";
import {get_acsrf_token} from "@/methods";
import {BanType} from "@/enums";
import {mapGetters} from "vuex";
import {maxLength, minLength, required} from "vuelidate/lib/validators";


export default {
  name: "FeaturedBan",
  props: ['featuredBan', 'options', 'loadingBanDetails', 'loadingBanDetailsError', 'banlist_id', 'selectedBanList'],
  components: {
    CopyableInput,
    HalfCircleSpinner,
    TimeAgo,
    BanFileUpload: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanFileUpload'),
    BanLinkUpload: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanLinkUpload'),
    BanExpiration: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanExpiration'),
    BanReason: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanReason'),
    BanDescription: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanDescription')
  },
  validations: {
    banForm: {
      identifier: {required, custom: true},
      reason: {required, minLength: minLength(3), maxLength: maxLength(40)},
      description: {maxLength: maxLength(400)}
    },
    featuredBan: {
      identifier: {required, custom: true},
      reason: {required, minLength: minLength(3), maxLength: maxLength(40)},
      description: {maxLength: maxLength(400)}
    }
  },
  methods: {
    async saveBanChanges() {
      this.$v.featuredBan.$touch();
      if (this.$v.featuredBan.$error) return;
      this.banInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500)); //???
      try {
        let files = [];
        this.featuredBan.evidence.files.forEach((file) => {
          files.push([file.resource_id, file.name]);
        });

        const acsrf_token = await get_acsrf_token();
        const payload = {
          acsrf_token: acsrf_token,
          type: this.featuredBan.type,
          banlist_id: this.banlist_id,
          ban_id: this.featuredBan.id,
          identifier: this.featuredBan.identifier,
          reason: this.featuredBan.reason,
          description: this.featuredBan.description,
          links: this.featuredBan.evidence.links,
          files: files,
          permanent: this.expirationForm.permanent,
          expiration: this.expirationForm.expiration,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        const response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/edit-ban`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if (response.ok) {
          this.$emit('edited');
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.add.success')
          });
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
        this.cancelEditing();
      }
      this.banInProgress = false;
      this.cancelEditing();
    },
    async revokeBan(banId, force_delete) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          ban_id: banId,
          force_delete: force_delete
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/ban-revoke`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if (response.ok) {
          let data = await response.json();
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.remove.success')
          });
          if (data.deleted) {
            this.$emit('deleted');
          } else this.$emit('edited');

        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
    setExpirationForm(){
      this.expirationForm.expiration = this.featuredBan.expires_at;
      this.expirationForm.permanent = this.expirationForm.expiration === null;
      if (this.expirationForm.permanent) {
        this.expirationForm.expiration = this.currentDateTime();
      }
      this.expirationForm.days = 0;
    },
    async editBanInit() {
      this.setExpirationForm();
      await new Promise(resolve => setTimeout(resolve, 500)); //???
      this.editMode = true;
    },
    currentDateTime() {
      let date = new Date();
      return `${date.getFullYear()}-${(date.getMonth() + 1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    cancelEditing() {
      this.$emit('updateRequired');
      this.editMode = false;
    },
    async removeSavedFile(fileId, fileName) { //TODO: remove fileName from this function.
      try{
        // Update view.
        let index = this.featuredBan.evidence.files.map((e) => {
          return e.resource_id;
        }).indexOf(fileId);
        if (index !== -1) this.featuredBan.evidence.files.splice(index, 1);
      }catch(error){
        this.$toast.error(this.$t('error.server.generic.message'));
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
  },
  data() {
    return {
      BanType: BanType,
      editMode: false,
      expirationForm: {
        expiration: "",
        permanent: false,
        days: 0
      }
    }
  }
}
</script>
<template>
  <div v-if="!loadingBanDetails && !loadingBanDetailsError">
    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-nowrap mb-0">
            <tbody>
            <tr>
              <th scope="row">
                <h6 v-if="!editMode" class="text-uppercase mb-0">{{ $t('banmanager.details.status') }}</h6>
                <h6 v-if="editMode" class="text-uppercase mb-0">{{ $t('banmanager.details.expiration') }}</h6>
              </th>
              <td>
                <template v-if="featuredBan.meta.active && !editMode">
                      <span class="badge badge-primary">
                        {{ $t('banmanager.view.database.active') }}
                      </span>
                </template>
                <template v-else-if="!featuredBan.meta.active && !editMode">
                      <span class="badge badge-warning text-black">
                        {{ $t('banmanager.view.database.inactive') }}
                      </span>
                </template>
                <span v-if="!editMode" class="small">
                      {{ $t('banmanager.view.database.expires') }}:
                      <template v-if="featuredBan.expires_at">
                        {{ $d(parseDate(featuredBan.expires_at), 'datetime', getDTLocale()) }}
                      </template>
                      <template v-else>
                        {{ $t('banmanager.view.database.never') }}
                      </template>
                    </span>
                <!--                    Edit expiration.-->
                <BanExpiration
                    v-if="editMode"
                    :banForm="expirationForm"
                    :options="options">
                </BanExpiration>
                <!--                    Edit expiration.-->
              </td>
            </tr>
            <tr>
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.type.label') }}</h6>
              </th>
              <td>
                <template v-if="featuredBan.type === BanType.CFTOOLS_ID">
                  <!-- Account ban -->
                  {{ $t('banmanager.details.type.cftools_id') }}
                </template>
                <template v-else-if="featuredBan.type === BanType.IPV4">
                  <!-- Dynamic ip ban -->
                  {{ $t('banmanager.details.type.ipv4') }}
                </template>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.identifier') }}</h6>
              </th>
              <td>
                <div style="max-width: 20rem !important;">
                  <CopyableInput :label="featuredBan.identifier" :text="featuredBan.identifier" size="sm"/>
                </div>
              </td>
            </tr>
            <tr v-if="featuredBan.user.cftools_id">
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.player') }}</h6>
              </th>
              <td>
                <router-link v-if="featuredBan.user.cftools_id" :to="{name: 'profile', params: {cftools_id: featuredBan.user.cftools_id}}"
                             target="_blank">
                  <img
                      v-if="featuredBan.user.avatar"
                      :src="featuredBan.user.avatar"
                      alt="profile picture"
                      class="rounded-circle avatar-xs"
                  >
                  <template v-if="!featuredBan.user.display_name">
                    {{ featuredBan.user.cftools_id }}
                  </template>
                  <template v-else>
                    {{ featuredBan.user.display_name }}
                  </template>
                </router-link>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.date') }}</h6>
              </th>
              <td>
                {{ $d(parseDate(featuredBan.created_at), 'datetime', getDTLocale()) }},
                <time-ago :datetime="parseDate(featuredBan.created_at)" :locale="getUILanguage()" :refresh="60"
                          long></time-ago>
              </td>
            </tr>
            <tr>
              <template v-if="featuredBan.issuer.cftools_id">
                <th scope="row">
                  <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.issuer') }}</h6>
                </th>
                <td>
                  <router-link v-if="featuredBan.issuer.cftools_id"
                               :to="{name: 'profile', params: {cftools_id: featuredBan.issuer.cftools_id}}"
                               target="_blank">
                    <img
                        :src="featuredBan.issuer.avatar"
                        alt="profile picture"
                        class="rounded-circle avatar-xs"
                    >
                    {{ featuredBan.issuer.display_name }}
                  </router-link>
                </td>
              </template>
              <template v-else>
                <th scope="row">
                  <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.source') }}</h6>
                </th>
                <td>
                  <template v-if="featuredBan.meta.flags.automated">
                    {{ $t('banmanager.view.database.automated') }}
                  </template>
                  <template v-else-if="featuredBan.meta.flags.imported">
                    {{ $t('banmanager.view.database.imported') }}
                  </template>
                  <template v-else-if="featuredBan.meta.flags.integration">
                    {{ $t('banmanager.view.database.integration') }}
                  </template>
                  <template v-else-if="featuredBan.meta.flags.shared">
                    {{ $t('banmanager.view.database.shared') }}
                  </template>
                </td>
              </template>
            </tr>
            <tr>
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.reason') }}</h6>
              </th>
              <td>
                <!--                    Edit ban reason-->
                <BanReason v-if="editMode" :banForm="featuredBan"></BanReason>
                <!--                    Edit ban reason.-->
                <!--                    Display current ban reason.-->
                <div v-else>
                  {{ featuredBan.reason }}
                </div>
                <!--                    Display current ban reason.-->
              </td>
            </tr>
            <!--                Display current ban description.-->
            <tr v-if="featuredBan.description && featuredBan.description !== 'None' && !editMode">
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.description') }}</h6>
              </th>
              <td>
                {{ featuredBan.description }}
              </td>
            </tr>
            <!--                Display current ban description.-->
            <!--                Edit description.-->
            <tr v-if="editMode">
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.description') }}</h6>
              </th>
              <td>
                <BanDescription :banForm="featuredBan"></BanDescription>
              </td>
            </tr>
            <!--                Edit description.-->
            <!--                Edit evidence.-->
            <tr v-if="featuredBan.evidence.files.length || editMode">
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.files') }}</h6>
              </th>
              <td>
                <ul class="ml-0 pl-0" style="list-style: none;">
                  <li v-for="file in featuredBan.evidence.files" :key="file.resource_id+Math.random()">
                    <div v-if="!file.size">
                    <i v-if="editMode" class="fas fa-times-hexagon text-danger" style="cursor: pointer;"
                       v-on:click="removeSavedFile(file.resource_id, file.name)"></i>
                    <span class="fad fa-file-alt align-middle ml-1" style="font-size: larger"></span>
                    <a :href="'https://usrcdn.cftools.cloud/s1/'+file.resource_id+'/'+file.name" target="_blank">
                      {{ file.name }}
                    </a>
                    <a :href="'https://usrcdn.cftools.cloud/s1/'+file.resource_id+'/'+file.name" target="_blank">
                        <span class="ml-1 small text-muted">
                          {{ $t('support.read.actions.download') }}
                        </span>
                    </a>
                    </div>
                  </li>
                </ul>
                <!--                    Upload new evidence files.-->
                <BanFileUpload
                    v-if="editMode"
                    :ban-form="featuredBan.evidence.files"
                    :banlist_id="banlist_id"
                    :create_ban="false"
                    :uploadedFilesNumber="featuredBan.evidence.files.length">
                </BanFileUpload>
<!--                {{featuredBan.evidence.files[0][1]}}-->
                <!--                    Upload new evidence files.-->
              </td>
            </tr>
            <tr v-if="featuredBan.evidence.links.length || editMode">
              <th scope="row">
                <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.links') }}</h6>
              </th>
              <td>
                <!--                Show current links.-->
                <ul v-if="!editMode" class="ml-0 pl-0" style="list-style: none;">
                  <li v-for="link in featuredBan.evidence.links" :key="link+Math.random()">
                    <i class="fad fa-external-link align-middle"></i>
                    <a :href="link" target="_blank">
                      {{ link }}
                    </a>
                  </li>
                </ul>
                <!--                Show current links.-->
                <!--                Edit links.-->
                <BanLinkUpload
                    v-if="editMode"
                    :banLinks="featuredBan.evidence.links">
                </BanLinkUpload>
                <!--                Edit links.-->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <!--             Edit/Save button.-->
        <button v-if="!editMode && (options.permissions.ban.temporary || options.permissions.ban.permanent) === true"
                class="btn btn-primary text-white btn-block" v-on:click="editBanInit()"
                :disabled="!(options.permissions.ban.temporary || options.permissions.ban.permanent)">
          Edit
        </button>
        <div v-else-if="editMode && (options.permissions.ban.temporary || options.permissions.ban.permanent)" class="btn-group btn-block" role="group">
          <button class="btn btn-primary text-white" v-on:click="saveBanChanges()">
            Save
          </button>
          <button class="btn btn-secondary text-white" v-on:click="cancelEditing()">
            Cancel
          </button>
        </div>

        <!--             Other buttons.-->
        <button v-if="featuredBan.meta.active" class="btn btn-warning text-black btn-block"
                v-on:click="revokeBan(featuredBan.id, false)">
          {{ $t('banmanager.details.revoke') }}
        </button>
        <button v-if="featuredBan.allowDelete" class="btn btn-outline-danger btn-block"
                v-on:click="revokeBan(featuredBan.id, true)">
          {{ $t('banmanager.details.delete') }}
        </button>
      </div>
    </div>
  </div>
  <div v-else-if="!loadingBanDetails && loadingBanDetailsError">
    <div class="row mt-4">
      <div class="col justify-content-center">
        <div class="info-component text-center align-middle mt-auto mb-auto">
          <div class="info-component-space">
            <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
          </div>
          <div class="row" style="margin-top: 20px;">
            <div class="col-lg-12">
              <h3 class="text-uppercase text-danger">{{ $t('error.server.generic.title') }}</h3>
              <h5 class="text-muted">{{ $t('error.server.generic.component') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="loadingBanDetails">
    <div class="info-component text-center align-middle mt-auto mb-auto">
      <div class="info-component-space">
        <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
      </div>
    </div>
  </div>
</template>
